@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landing-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-section {
  position: relative;
  z-index: 10;
  text-align: center;
  color: white;
  padding-top: 35vh; /* Increased padding for larger hero section */
  padding-bottom: 40vh;
  background: rgba(0, 0, 0, 0.6); /* To reduce 3D elements' presence */
}

.hero-section h1 {
  font-size: 5em; /* Increased font size */
  font-weight: 500; /* Lighter font weight */
  margin-bottom: 30px; /* Increased margin for better spacing */
}

.typewriter-text {
  color: #c0392b;
}

.subheading {
  font-size: 1.5em; /* Smaller font size for subheading */
  color: #ddd; /* Darker color for better contrast */
  margin-bottom: 50px; /* Increased margin for better spacing */
}

.hero-button {
  display: inline-block;
  padding: 20px 40px; /* Increased padding */
  font-size: 1.8em; /* Increased font size */
  color: white;
  background-color: #e74c3c;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 30px; /* Increased margin for better spacing */
}

.hero-button:hover {
  background-color: #c0392b;
}

.video-section {
  position: relative;
  z-index: 10;
  padding: 100px 20px 50px 20px; /* Increased padding above the video section */
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70vh; /* Increased height for larger video section */
}

.video-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 40px;
}

iframe {
  max-width: 60%;
  border-radius: 10px;
  margin-right: 20px;
}

.video-description {
  max-width: 40%;
}

.video-description h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.video-description p {
  font-size: 1.5em;
  color: #666;
}

.video-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5em;
  color: white;
  background-color: #e74c3c;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.video-button:hover {
  background-color: #c0392b;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  position: relative;
  z-index: 10;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 3em;
  }

  .subheading {
    font-size: 1.2em;
  }

  .hero-button {
    font-size: 1.2em;
    padding: 15px 30px;
  }

  .video-container {
    flex-direction: column;
  }

  iframe {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .video-description {
    max-width: 100%;
  }

  .video-description h2 {
    font-size: 2em;
  }

  .video-description p {
    font-size: 1.2em;
  }

  .video-button {
    font-size: 1.2em;
  }
}
