@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.keyword-finder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c3e50;
  margin: auto;
  padding: 20px;
  min-height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  gap: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.header {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 20px;
  color: #bec3c7;
  animation: fadeInDown 0.5s ease;
}

.header span {
  color: #e74c3c;
  font-weight: 700;
}

.input-field, .custom-name-input {
  width: 60%;
  max-width: 600px;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 25px;
  border: 1px solid #bdc3c7;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.input-field:focus, .custom-name-input:focus {
  border-color: #e74c3c;
  box-shadow: 0 4px 15px rgba(231, 76, 60, 0.2);
}

.generate-btn, .clear-custom-name-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  font-size: 18px;
  border-radius: 25px;
  background: #e74c3c;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 0.5s ease;
}

.generate-btn:hover, .clear-custom-name-btn:hover {
  background: #c0392b;
}

.loading-text {
  font-size: 18px;
  margin-top: 10px;
  color: #e74c3c;
  animation: fadeIn 0.5s ease;
}

.tags-container, .title-suggestions-container, .description-container, .thumbnail-container {
  width: 80%;
  max-width: 800px;
  background: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.5s ease;
  margin-top: 20px;
}

.tag {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  transition: background 0.3s ease;
  animation: fadeIn 0.5s ease;
  margin: 5px;
}

.tag-green {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.tag-yellow {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}

.copy-btn, .copy-title-btn, .copy-description-btn, .download-btn {
  background: #e74c3c;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;
}

.copy-btn:hover, .copy-title-btn:hover, .copy-description-btn:hover, .download-btn:hover {
  background: #c0392b;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #e74c3c;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.notification.show {
  opacity: 1;
  transform: translateY(0);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInUp 0.5s ease;
}

.char-counter {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

.explanation {
  margin-top: 20px;
  font-size: 16px;
}

.green-text {
  color: #155724;
}

.yellow-text {
  color: #856404;
}

.thumbnail-image {
  width: 100%;
  border-radius: 10px;
}

.custom-name-container {
  display: flex;
  flex-direction: column; /* To stack the elements vertically */
  align-items: center; /* Center elements horizontally */
  justify-content: center; /* Center elements vertically */
  gap: 10px;
  margin-top: 20px;
  text-align: center; /* Center text inside the input field */
}

.title-suggestions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.title {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease;
  position: relative;
}

.title:hover::after {
  content: "Click to copy";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #e74c3c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-heading {
  font-size: 28px;
  color: #bec3c7;
  margin-top: 40px;
  margin-bottom: 20px;
}
